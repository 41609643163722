import assest from "@/json/assest";
import CustomButton from "@/ui/Buttons/CustomButton";
import CustomButtonGray from "@/ui/Buttons/CustomButtonGray";
import styled from "@emotion/styled";
import { Grid, List, ListItem, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import Image from "next/image";
import Link from "next/link";
import React from "react";

const JoinSec = styled(Box)`
  .join_sec {
    position: relative;
    z-index: 1;

    &.cmn_gap {
      @media (max-width: 575px) {
        padding-top: 0;
      }
    }
    &::before {
      content: "";
      position: absolute;
      top: -122px;
      right: 0;
      background: radial-gradient(
        59% 41% at 50% 76%,
        rgba(71, 113, 255, 0.4) 0%,
        rgba(71, 113, 255, 0) 100%
      );
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#4c36fd+0,4c36fd+100&0.16+0,0+100 */
      -webkit-filter: blur(169.5px);
      filter: blur(185.5px);
      -webkit-transform: rotate(-99.39deg);
      -moz-transform: rotate(-99.39deg);
      -ms-transform: rotate(-99.39deg);
      transform: rotate(-99.39deg);
      width: 100vh;
      height: 100vh;
      @media(max-width:1199px){
        background: -moz-radial-gradient(center, ellipse cover,  rgba(76,54,253,0.16) 0%, rgba(76,54,253,0) 100%); /* FF3.6-15 */
        background: -webkit-radial-gradient(center, ellipse cover,  rgba(76,54,253,0.16) 0%,rgba(76,54,253,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: radial-gradient(ellipse at center,  rgba(76,54,253,0.16) 0%,rgba(76,54,253,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#294c36fd', endColorstr='#004c36fd',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
      }
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: -40%;
      background: radial-gradient(
        44% 54% at 50% 29%,
        rgba(133, 239, 223, 0.24) 0%,
        rgba(71, 113, 255, 0) 100%
      );
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#85efdf+0,4771ff+100&0.24+0,0+100 */


      -webkit-filter: blur(169.5px);
      -webkit-filter: blur(169.5px);
      -webkit-filter: blur(169.5px);
      filter: blur(169.5px);
      -webkit-transform: rotate(-99.39deg);
      -moz-transform: rotate(-99.39deg);
      -ms-transform: rotate(-99.39deg);
      -webkit-transform: rotate(-99.39deg);
      -moz-transform: rotate(-99.39deg);
      -ms-transform: rotate(-99.39deg);
      -webkit-transform: rotate(-99.39deg);
      -moz-transform: rotate(-99.39deg);
      -ms-transform: rotate(-99.39deg);
      transform: rotate(-99.39deg);
      width: 100vw;
      height: 100vh;
      pointer-events: none;

      @media(max-width:1199px){
        background: -moz-radial-gradient(center, ellipse cover,  rgba(76,54,253,0.16) 0%, rgba(76,54,253,0) 100%); /* FF3.6-15 */
        background: -webkit-radial-gradient(center, ellipse cover,  rgba(76,54,253,0.16) 0%,rgba(76,54,253,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: radial-gradient(ellipse at center,  rgba(76,54,253,0.16) 0%,rgba(76,54,253,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#294c36fd', endColorstr='#004c36fd',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
      }
      
    }

    .join_left {
      button {
        min-width: 233px;
        padding: 11px 20px;
        @media (max-width: 1199px) {
          min-width: 180px;
        }
      }
    }
  }
  .join_title {
    position: relative;
    z-index: 99;
    h2 {
      text-transform: none;
      max-width: 478px;
      margin-bottom: 50px;
      @media (max-width: 599px) {
        text-align: center;
        margin-bottom: 0;
      }
    }
    .pink_text {
      color: var(--activeColor);
    }
  }
  .small_txt {
    font-size: 20px;
    max-width: 492px;
    @media (max-width: 1199px) {
      font-size: 14px;
      max-width: 350px;
    }
  }
  .join_list_item {
    padding-left: 30px;
    flex-basis: calc(100% - 52px);
    max-width: calc(100% - 52px);
    @media (max-width: 479px) {
      padding-left: 25px;
      flex-basis: calc(100% - 40px);
      max-width: calc(100% - 40px);
    }
    h3.MuiTypography-root {
      font-weight: 800;
      font-size: 20px;
      letter-spacing: 0.01em;
      margin-bottom: 20px;
      color: var(--white);
      @media (max-width: 1199px) {
        margin-bottom: 15px;
      }
      @media (max-width: 375px) {
        font-size: 20px;
      }
    }
    p.MuiTypography-root {
      font-size: 16px;
      max-width: 275px;
      margin-bottom: 30px;
      color: var(--white);
      @media (max-width: 1199px) {
        font-size: 14px;
        margin-bottom: 30px;
      }
      @media (max-width: 599px) {
        margin-bottom: 0px;
      }
    }
    .transprent_btn {
      font-family: "Manrope";
      font-weight: 700;
      font-size: 20px;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      letter-spacing: 0.01em;
      color: var(--white);
      mix-blend-mode: normal;
      padding: 14px 54px;
      border: 1px solid rgba(255, 255, 255, 0.22);
      border-left: none;
      border-right: none;
      background: url("/assets/images/ButtonTer.svg") no-repeat center;
      background-size: cover;
      border-radius: 5px;
      min-width: 382px;
      &:hover {
        background: url("/assets/images/ButtonTerHover.svg") no-repeat center;
        background-size: cover;
      }
      @media (max-width: 1199px) {
        font-size: 14px;
        min-width: 200px;
      }
      @media (max-width: 1199px) {
        font-size: 14px;
        padding: 14px 10px;
      }
    }
  }
  .join_right_over {
    position: relative;
    &::before {
      content: "";
      width: 100vw;
      height: 100%;
      -webkit-background-size: cover;
      background-size: cover;
      background-blend-mode: screen;
      mix-blend-mode: screen;
      position: absolute;
      left: 0px;
      top: 0;
      pointer-events: none;
      z-index: 0;
      background: radial-gradient(
        91.33% 57.64% at -24.75% 49.96%,
        rgba(76, 54, 253, 0.34) 0%,
        rgba(76, 54, 253, 0) 100%
      );
    }
  }
  .join_rgt {
    padding-left: 60px;
    padding-top: 60px;
    @media (max-width: 599px) {
      padding-left: 0;
    }

    li {
      padding: 0;
      align-items: flex-start;
      &:not(:last-child) {
        margin-bottom: 80px;
        @media (max-width: 599px) {
          margin-bottom: 30px;
        }
      }
    }
  }

  .list_number {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-size: 100px;
    width: 52px;
    flex-basis: 52px;
    height: 50px;
    position: relative;

    background: radial-gradient(
        93.84% 117.5% at 50.55% 50.55%,
        #5cf1ff 0%,
        #247f88 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    border: 0.571433px solid rgba(255, 56, 235, 0.16);
    border-radius: 2.85716px;
    @media (max-width: 479px) {
      width: 40px;
      height: 40px;
      flex-basis: 40px;
    }
    p.MuiTypography-root {
      font-weight: 800;
      font-size: 40px;
      text-align: center;
      letter-spacing: 0.01em;
      color: var(--white);
      @media (max-width: 1199px) {
        font-size: 45px;
      }
      @media (max-width: 479px) {
        font-size: 25px;
      }
    }
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      background: url("../assets/icons/number_back.svg");
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }
  }
  .join_fig {
    position: relative;
    z-index: 1;
    figure {
      height: 700px;
      @media (max-width: 479px) {
        height: 500px;
      }
      @media (max-width: 374px) {
        height: auto;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .btn_list {
    display: flex;
    align-items: center;
    li {
      padding: 0;
      width: auto;
      display: inline-block;
      &:not(:last-child) {
        margin-right: 20px;
      }
      button {
        padding: 10px 17px;
        min-width: 233px;
      }
    }
  }
  .see_btn.launch {
    text-align: center;
    margin-top: 50px;

    span{
      font-size: 16px;

    }
    button{
      min-width: 165px;
    }
  }
`;

export default function CommonJoinSec() {
  const list_items = [
    {
      list_head: "Purchase $PLF Token",
      list_text:
        "PLF is PulseFinity token that enables its holders to participate in Launches, stake for passive income and tier benefits.",
      // list_btn_content: "Access $PLF token sacrifice",
    },
    {
      list_head: "Choose your stake tier",
      list_text:
        "There are six staking tiers which range from $100-$10k+ in $PLF USD value. Depending on your tier will determine different stake pool access, APY returns, Launch allocation size and many more cool features!",
      // list_btn_content: "Access $PLF token sacrifice",
    },
    {
      list_head: "Stake $PLF",
      list_text:
        "Add your $PLF to one of our staking pools and earn passive income and access to Launch sales.",
      // list_btn_content: "Start Now",
    },
    {
      list_head: "You’re all set!",
      list_text:
        "Now you can purchase revolutionary token sales on Pulsechain before everyone else.",
      // list_btn_content: "",
    },
  ];
  return (
    <>
      <JoinSec>
        <Box className="join_sec cmn_gap">
          <Container fixed>
            <Box className="join_wrap">
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Box className="join_left">
                    <Box className="join_fig">
                      <figure>
                        <Image
                          src={assest.bulb2}
                          alt="img"
                          width="1000"
                          height="1000"
                        ></Image>
                      </figure>
                    </Box>
                    <Box className="join_title">
                      <Typography variant="h2" className="cmn_head">
                        How to join revolutionary Launches on Pulsechain
                      </Typography>
                    </Box>
                    <List className="btn_list">
                      <ListItem
                        sx={{
                          display: {
                            xs: "none !important",
                            sm: "block !important",
                          },
                        }}
                      >
                        <CustomButton type="button"
                          onClick={() => window.open("/price-discovery", "_self")}
                        >
                          <Typography variant="button">
                            Price Discovery 
                          </Typography>
                      </CustomButton>
                      </ListItem>
                      <ListItem
                        sx={{
                          display: {
                            xs: "none !important",
                            sm: "block !important",
                          },
                        }}
                      >
                        <CustomButtonGray type="button">
                        <Typography 
                          variant="button" 
                          onClick={() => window.open("https://pulsefinity.gitbook.io/pulsefinity/participating-in-pulsefinity-idos/how-to-enter-a-ido", "_blank")}
                        >
                          How To Start
                        </Typography>
                        </CustomButtonGray>
                      </ListItem>
                    </List>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12} className="join_right_over">
                  <Box className="join_rgt">
                    <List>
                      {list_items.map((data, index) => {
                        return (
                          <ListItem key={index}>
                            <Box className="list_number">
                              <Typography variant="body1">
                                {index + 1}
                              </Typography>
                            </Box>
                            <Box className="join_list_item">
                              <Typography variant="h3">
                                {data.list_head}
                              </Typography>
                              <Typography variant="body1">
                                {data.list_text}
                              </Typography>
                            </Box>
                          </ListItem>
                        );
                      })}
                    </List>
                    <Box
                      className="see_btn launch"
                      sx={{
                        display: {
                          xs: "block !important",
                          sm: "none !important",
                        },
                      }}
                    >
                      <CustomButton 
                        type="button"
                        onClick={() => window.open("/staking", "_self")}
                      >
                        <Typography variant="button">Stake Now</Typography>
                      </CustomButton>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6}></Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </JoinSec>
    </>
  );
}

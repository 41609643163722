import assest from "@/json/assest";
import CustomButton from "@/ui/Buttons/CustomButton";
import CustomButtonGray from "@/ui/Buttons/CustomButtonGray";
import { Box, Container, Typography } from "@mui/material";
import { styled } from "@mui/system";
import Image from "next/image";
import Link from "next/link";
import React from "react";

const ApplySec = styled(Box)`
  .apply_sec {
    padding: 200px 0;
    @media (max-width: 1199px) {
      padding: 100px 0;
    }
    @media (max-width: 599px) {
      padding: 50px 0;
      margin-top: 155px;
    }
  }
  .apply_wrap_upper_content {
    max-width: 800px;
    position: relative;
    z-index: 5;
    @media (max-width: 1199px) {
      max-width: 500px;
    }
    @media (max-width: 991px) {
      max-width: 375px;
    }
    @media (max-width: 599px) {
      max-width: none;
      order: 2;
      text-align: center;
      margin-top: -35px;
    }
    p {
      font-weight: 800;
      font-size: 16px;
      letter-spacing: 0.01em;
      color: var(--white);
      max-width: 554px;
      @media (max-width: 991px) {
        font-size: 14px;
      }
    }
    h2.MuiTypography-root {
      margin-bottom: 20px;
      text-transform: none;
      @media (max-width: 599px) {
        font-size: 28px;
      }
    }
    .apply_wrap_btm_mobile{
      display: none;
      @media (max-width:599px) {
        display: block;
        text-align: center;
        margin-top: 28px;
        button{
          min-width: 167px;
        }
      }
    }
  }
  .box_wrap {
    background: -moz-linear-gradient(
      left,
      rgba(90, 235, 251, 1) 0%,
      rgba(90, 235, 251, 0) 59%,
      rgba(90, 235, 251, 0) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(90, 235, 251, 1) 0%,
      rgba(90, 235, 251, 0) 59%,
      rgba(90, 235, 251, 0) 100%
    );
    background: linear-gradient(
      to right,
      rgba(90, 235, 251, 1) 0%,
      rgba(90, 235, 251, 0) 59%,
      rgba(90, 235, 251, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5aebfb', endColorstr='#005aebfb',GradientType=1 );
    padding: 2px;
    border-radius: 10px;
    @media (max-width:599px) {
      background: var(--activeColor);
      padding: 1px;
    }
  }
  .apply_wrap_upper {
    padding: 30px 50px;
    position: relative;
    z-index: 9;
    border-radius: 10px;
    background: var(--primaryBlue);
    max-width: 1067px;
    @media (max-width: 991px) {
      padding: 30px 20px;
    }
    @media (max-width: 599px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .rocket_img {
      position: absolute;
      right: -17%;
      top: 55%;
      transform: translateY(-50%);
      height: 900px;
      @media (max-width: 1199px) {
        height: 600px;
        right: 0;
        width: 166px;
      }
      @media (max-width: 991px) {
        height: 275px;
        /* right: -12%; */
      }
      @media (max-width: 599px) {
        position: static;
        transform: none;
        order: 1;
        height: auto;
        margin-top: -255px;
        width: auto;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .apply_wrap_btm {
    margin-top: 30px;
    display: flex;
    align-items: center;
    .MuiButtonBase-root {
      width: 100%;
      max-width: 300px;
      &:first-child {
        margin-right: 20px;
      }

      @media (max-width: 479px) {
        min-width: auto;
        width: 100%;
      }
    }
    @media (max-width:599px) {
      display: none;
    }
  }
  .apply_logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 236px;
    margin-bottom: 25px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    @media (max-width: 991px) {
      width: 165px;
      margin-bottom: 15px;
    }
    @media (max-width: 599px) {
      margin: 0 auto 15px auto;
    }
  }
`;
const ApplyBox = () => {
  return (
    <ApplySec>
      <Box className="apply_sec cmn_gap">
        <Container fixed>
          <Box className="apply_wrap">
            <Box className="box_wrap">
              <Box className="apply_wrap_upper">
                <Box className="apply_wrap_upper_content">
                  <Link href={"/"} className="apply_logo">
                    <Image
                      src={assest.pulseicon}
                      width="236"
                      height="24"
                      alt="img"
                    ></Image>
                  </Link>
                  <Typography variant="h2" className="cmn_head">
                    Apply for your Project to launch at PulseFinity
                  </Typography>
                  <Typography variant="body1">
                    If you have an amazing project that you'd like to launch at
                    PulseFinity, apply now!
                  </Typography>
                  <Box className="apply_wrap_btm_mobile">
                    <CustomButton 
                      type="button"
                      onClick={() => window.open("/create-launchpad", "_self")}
                    >
                      <Typography variant="button">Launch Now</Typography>
                    </CustomButton>
                  </Box>
                </Box>
                <figure className="rocket_img">
                  <Image
                    src={assest.rocket_img}
                    alt=""
                    width={350}
                    height={1050}
                   
                  ></Image>
                </figure>
              </Box>
            </Box>

            <Box className="apply_wrap_btm">
              <CustomButton type="button"
                onClick={() => window.open("/price-discovery", "_self")}
              >
                <Typography variant="button">Price Discovery</Typography>
              </CustomButton>
              <CustomButtonGray type="button">
                <Typography 
                  variant="button" 
                  onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSc0cg_tWRzNMU_y_5Ur0jbbAinAlb4nJULpJ62A2CEKNej R_A/viewform", "_blank")}
                >
                  How To Start
                </Typography>
              </CustomButtonGray>
            </Box>
          </Box>
        </Container>
      </Box>
    </ApplySec>
  );
};

export default ApplyBox;

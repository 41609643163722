import assest from "@/json/assest";
import CustomButton from "@/ui/Buttons/CustomButton";
import CustomButtonGray from "@/ui/Buttons/CustomButtonGray";
import { Box, Container, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import Image from "next/image";
import React from "react";

const LaunchSec = styled(Box)`
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 22px;
    right: 0;

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#85efdf+0,4771ff+100&0.24+0,0+100 */
    background: -moz-radial-gradient(center, ellipse cover,  rgba(133,239,223,0.24) 0%, rgba(71,113,255,0) 100%); /* FF3.6-15 */
    background: -webkit-radial-gradient(center, ellipse cover,  rgba(133,239,223,0.24) 0%,rgba(71,113,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center,  rgba(133,239,223,0.24) 0%,rgba(71,113,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3d85efdf', endColorstr='#004771ff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

    -webkit-filter: blur(169.5px);
    filter: blur(169.5px);
    -webkit-transform: rotate(-99.39deg);
    -moz-transform: rotate(-99.39deg);
    -ms-transform: rotate(-99.39deg);
    transform: rotate(178deg);
    width: 100vh;
    height: 100vh;
    /* @media(max-width:1199px){
      display: none;
    }
    @media(max-width:599px){
      display: block;
    } */
  }
  .launch_sec_box {
    position: relative;
    background: -moz-linear-gradient(
      left,
      rgba(92, 241, 255, 0) 0%,
      rgba(92, 241, 255, 0) 25%,
      rgba(92, 241, 255, 1) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(92, 241, 255, 0) 0%,
      rgba(92, 241, 255, 0) 25%,
      rgba(92, 241, 255, 1) 100%
    );
    background: linear-gradient(
      to right,
      rgba(92, 241, 255, 0) 0%,
      rgba(92, 241, 255, 0) 25%,
      rgba(92, 241, 255, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#005cf1ff', endColorstr='#5cf1ff',GradientType=1 );

    border-radius: 5px;
    padding: 2px;
      @media (max-width:599px) {
       background:var(--activeColor);
       padding: 1px;
      }
    .launch_sec {
      background: var(--primaryBlue);
      background-size: cover;
      border-radius: 5px;
      z-index: 9;
      padding: 50px;
      position: relative;
      @media (max-width: 575px) {
        padding: 50px 20px 35px 20px;
      }
    }

    .setting_img {
      position: absolute;
      top: -40px;
      left: 0;
      @media (max-width: 1199px) {
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        top: -150px;
      }
      @media (max-width: 899px) {
        position: static;
        margin: 0 auto;
        text-align: center;
      }
      @media (max-width: 599px) {
        margin: -203px 0 0px 0px;
      }

      img {
        width: 100% !important;
        max-width: 500px !important;
        @media (max-width: 1599px) {
          width: 470px!important;
    height: 487px !important;
        }
        /* @media (max-width: 1199px) {
          width: 350px !important;
        } */
        @media (max-width: 1199px) {
          width: auto !important;
          height: 250px !important;
        }
      }
    }
    .pulse_icon {
      margin: 0 0 5px 0;
      img {
        width: 250px !important;
        @media (max-width: 599px) {
          width: 167px !important;
        }
      }
      @media (max-width: 599px) {
        text-align: center;
      }
    }
    .pulse_cont {
      h2 {
        margin-bottom: 26px;
        @media (max-width: 599px) {
          text-align: center;
          margin-top: 20px;
          margin-bottom: 36px;
        }
      }
      .pulse_cont_box {
        display: flex;
        align-items: center;
        margin: 20px 0;
        img {
          width: 40px !important;
          height: 40px !important;
          margin: 0 10px 0 0;
        }
        h3 {
          font-weight: 800;
          font-size: 20px;
          letter-spacing: 0.01em;
          color: var(--white);
          max-width: 276px;
          width: 100%;
          @media (max-width: 1199px) {
            font-size: 14px;
            max-width: 200px;
          }
        }
      }
    }
    .launch_wrap {
      max-width: 690px;
      margin-left: auto;
      /* @media (max-width: 1199px) {
        max-width: 550px;
      } */
      @media (max-width: 1199px) {
        max-width: 100%;
        margin-left: 0;
      }
    }
  }

  .see_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px auto 0;
    button {
      width: 100%;
      max-width: 300px;
      &:first-child {
        margin-right: 20px;
      }
    }
    &.launch{ 
      span{
        font-size: 16px;
      }

    }
  }
  .see_btn.launch {
    text-align: center;

    span{
      font-size: 16px;

    }
    button{
      max-width: 165px;
      margin: 0;
    }
  }
`;
const LaunchSection = () => {
  return (
    <LaunchSec>
      <Box className="cmn_gap">
        <Container fixed>
          <Box className="launch_sec_box">
            <Box className="launch_sec">
              <Box className="setting_img">
                <Image
                  src={assest.clog}
                  alt="img"
                  width={500}
                  height={500}

                />
              </Box>
              <Box className="launch_wrap">
                <Grid container spacing={2}>
                  <Grid item lg={12}>
                    <Box className="pulse_icon">
                      <Image
                        src={assest.pulseicon}
                        alt="img"
                        width={52}
                        height={23}
                        layout="responsive"
                      />
                    </Box>
                    <Box className="pulse_cont">
                      <Typography variant="h2" className="cmn_head">
                        Protected Launch
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item md={6} sm={6} xs={12}>
                          <Box className="pulse_cont_box">
                            <Image
                              src={assest.govtIcon}
                              alt="img"
                              width={52}
                              height={23}
                              layout="responsive"
                            />
                            <Typography variant="h3">
                              Governance Voting system for projects
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Box className="pulse_cont_box">
                            <Image
                              src={assest.strict}
                              alt="img"
                              width={52}
                              height={23}
                              layout="responsive"
                            />
                            <Typography variant="h3">
                              Strict audit policies for project listings
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <Box className="pulse_cont_box">
                            <Image
                              src={assest.penaltyIcon}
                              alt="img"
                              width={52}
                              height={23}
                              layout="responsive"
                            />
                            <Typography variant="h3">
                              Reinforced penalties for non-compliant projects
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Box className="pulse_cont_box">
                            <Image
                              src={assest.refund}
                              alt="img"
                              width={52}
                              height={23}
                              layout="responsive"
                            />

                            <Typography variant="h3">
                              Launch refund for participants
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
                <Box className="see_btn launch" sx={{ display: { xs: "flex !important", sm: "none !important" } }}>
                  <CustomButton 
                    type="button"
                    onClick={() => window.open("/create-launchpad", "_self")}
                  >
                    <Typography variant="button">Launch Project</Typography>
                  </CustomButton>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="see_btn" sx={{ display: { xs: "none !important", sm: "flex !important" } }}>
            <CustomButton type="button"
              onClick={() => window.open("/price-discovery", "_self")}
            >
              <Typography variant="button">Price Discovery</Typography>
            </CustomButton>
            <CustomButtonGray type="button">
            <Typography 
              variant="button" 
              onClick={() => window.open("https://pulsefinity.gitbook.io/pulsefinity/participating-in-pulsefinity-idos/how-to-enter-a-ido", "_blank")}
            >
              How To Start
            </Typography>
            </CustomButtonGray>
          </Box>
        </Container>
      </Box>
    </LaunchSec>
  );
};

export default LaunchSection;

import assest from "@/json/assest";
import { Box, Container, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import Image from "next/image";
import React from "react";

const PartnerSec = styled(Box)`
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 22px;
    right: 0;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(133, 239, 223, 0.24) 0%,
      rgba(71, 113, 255, 0) 100%
    );
    filter: blur(169.5px);
    -webkit-transform: rotate(-99.39deg);
    -moz-transform: rotate(-99.39deg);
    -ms-transform: rotate(-99.39deg);
    transform: rotate(178deg);
    width: 100vh;
    height: 100vh;
  }
  .text-center {
    text-align: center;
  }
  .cmn_hdr {
    margin-bottom: 100px;
    @media (max-width: 1199px) {
      margin-bottom: 50px;
    }
  }
  .partner_otr {
    text-align: center;
    padding: 30px 20px;
    position: relative;
    @media (max-width: 374px) {
      padding: 15px 10px;
    }
    .hover_lay {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 335px;
      height: 335px;
      border-radius: 100%;
      background: radial-gradient(
        66.42% 60.72% at 50% 50%,
        #f0f0f0 11.96%,
        #000000 81.8%
      );
      background-blend-mode: color-dodge;
      mix-blend-mode: color-dodge;
      opacity: 0.5;
      /* transform: rotate(90deg); */
      opacity: 0;
      transition: opacity 0.5s ease;
      z-index: 9;
    }
    &:hover {
      .hover_lay {
        opacity: 1;
        z-index: 9;
      }
    }
    .partner_title {
      font-weight: 500;
      font-size: 23.0142px;
      text-align: center;
      letter-spacing: 0.01em;
      color: var(--white);
      @media (max-width: 1199px) {
        font-size: 17px;
      }
      @media (max-width: 374px) {
        font-size: 13px;
      }
    }
    figure {
      width: auto;
      height: 95px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-bottom: 25px;
      @media (max-width: 1199px) {
        height: 50px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .partner_content_wrap{
    position: relative;
    &::before{
      content: '';
    position: absolute;
    top: -111px;
    left: 121px;
    width: 615.78px;
    height: 218px;
    background: radial-gradient(31.96% 18.35% at 50% 50%, #E3E3E3 0%, #000000 100%);
    background-blend-mode: color-dodge;
    mix-blend-mode: color-dodge;
    opacity: 0.5;
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
    z-index: -1;
 }
 &::after{
  content: '';
    position: absolute;
    bottom: -111px;
    right: 121px;
    width: 615.78px;
    height: 218px;
    background: radial-gradient(31.96% 18.35% at 50% 50%, #E3E3E3 0%, #000000 100%);
    background-blend-mode: color-dodge;
    mix-blend-mode: color-dodge;
    opacity: 0.5;
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
    z-index: -1;
 }
  }
  .partner_content {
    padding: 50px 0 70px;
    position: relative;
    background: radial-gradient(47.09% 79.19% at 50% 50%, rgba(0, 0, 0, 0.69) 0%, rgba(0, 0, 0, 0) 100%);
   
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      bottom: 0;
  background: -moz-linear-gradient(
        left,
        rgba(227, 227, 227, 0) 0%,
        rgba(227, 227, 227, 1) 35%,
        rgba(227, 227, 227, 1) 69%,
        rgba(16, 20, 49, 0.18) 100%
      ); 
      background: -webkit-linear-gradient(
        left,
        rgba(227, 227, 227, 0) 0%,
        rgba(227, 227, 227, 1) 35%,
        rgba(227, 227, 227, 1) 69%,
        rgba(16, 20, 49, 0.18) 100%
      ); 
      background: linear-gradient(
        to right,
        rgba(227, 227, 227, 0) 0%,
        rgba(227, 227, 227, 1) 35%,
        rgba(227, 227, 227, 1) 69%,
        rgba(16, 20, 49, 0.18) 100%
      ); 
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00e3e3e3', endColorstr='#2e101431',GradientType=1 ); 

      pointer-events: none;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      top: 0;
      background: -moz-linear-gradient(
        left,
        rgba(227, 227, 227, 0) 0%,
        rgba(227, 227, 227, 1) 35%,
        rgba(227, 227, 227, 1) 69%,
        rgba(16, 20, 49, 0.18) 100%
      ); 
      background: -webkit-linear-gradient(
        left,
        rgba(227, 227, 227, 0) 0%,
        rgba(227, 227, 227, 1) 35%,
        rgba(227, 227, 227, 1) 69%,
        rgba(16, 20, 49, 0.18) 100%
      ); 
      background: linear-gradient(
        to right,
        rgba(227, 227, 227, 0) 0%,
        rgba(227, 227, 227, 1) 35%,
        rgba(227, 227, 227, 1) 69%,
        rgba(16, 20, 49, 0.18) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00e3e3e3', endColorstr='#2e101431',GradientType=1 ); 

      pointer-events: none;
      @media (max-width: 599px) {
        display: none;
      }
    }
  }
  .partner_wrap {
    position: relative;
    padding-top: 60px;
    &::after{
      display: none;
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 2px;
      top: 0;
      background: -moz-linear-gradient(
        left,
        rgba(227, 227, 227, 0) 0%,
        rgba(227, 227, 227, 1) 35%,
        rgba(227, 227, 227, 1) 69%,
        rgba(16, 20, 49, 0.18) 100%
      ); 
      background: -webkit-linear-gradient(
        left,
        rgba(227, 227, 227, 0) 0%,
        rgba(227, 227, 227, 1) 35%,
        rgba(227, 227, 227, 1) 69%,
        rgba(16, 20, 49, 0.18) 100%
      ); 
      background: linear-gradient(
        to right,
        rgba(227, 227, 227, 0) 0%,
        rgba(227, 227, 227, 1) 35%,
        rgba(227, 227, 227, 1) 69%,
        rgba(16, 20, 49, 0.18) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00e3e3e3', endColorstr='#2e101431',GradientType=1 ); 

      pointer-events: none;
      @media (max-width: 599px) {
        display: block;
      }
    }
  }
`;
const PartnerSection = () => {
  const partner_img = [
    {
      image_path: `${assest.partner1}`,
      partner_name: "Assure Defi",
    },
    /*{
      image_path: `${assest.partner2}`,
      partner_name: "Khaos",
    }*/,
    {
      image_path: `${assest.partner3}`,
      partner_name: "0xGuard",
    },
    {
      image_path: `${assest.partner4}`,
      partner_name: "Solidproof",
    },
    {
      image_path: `${assest.partner5}`,
      partner_name: "SecuriLab",
    },
    {
      image_path: `${assest.partner6}`,
      partner_name: "PulseLife",
    },
    {
      image_path: `${assest.partner7}`,
      partner_name: "InterFi Network",
    },
    {
      image_path: `${assest.partner8}`,
      partner_name: "Coin Fabrik",
    },
  ];
  return (
    <PartnerSec>
      <Box className="partner_sec cmn_gap">
        <Container fixed>
          <Box className="partner_wrap">
            <Box className="cmn_hdr text-center">
              <Typography variant="h2" className="cmn_head">
                Our Partners
              </Typography>
            </Box>
            <Box className="partner_content_wrap">
            <Box className="partner_content">
              <Grid container spacing={2}>
                {partner_img.map((data, index) => {
                  return (
                    <Grid item  md={3} xs={6} key={index}>
                      <Box className="partner_otr">
                        <span className="hover_lay"></span>
                        <figure>
                          <Image
                            src={data.image_path}
                            alt="img"
                            width="50"
                            height="50"
                          ></Image>
                        </figure>
                        <Typography variant="h3" className="partner_title">
                          {data.partner_name}
                        </Typography>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
            </Box>
           
          </Box>
        </Container>
      </Box>
    </PartnerSec>
  );
};

export default PartnerSection;

import { ButtonType } from "@/interface/common.interface";
import { Button, CircularProgress } from "@mui/material";
import { memo, useEffect, useState } from "react";
import styles from "@/styles/components/buttongray.module.scss"

const CustomButtonMemoGray = ({
    children,
    variant = "contained",
    disabled = false,
    onClick,
    color = "inherit",
    size = "medium",
    fullWidth = false,
    endIcon,
    startIcon,
    type,
    loading = false
  }: ButtonType) => {
    const [className, setClassName] = useState(styles.button_gray);
  
    useEffect(() => {
      if (variant === "text") {
        setClassName(styles.buttonText);
      } else if (variant === "outlined") {
        setClassName(styles.buttonOutlined);
      } else {
        setClassName(styles.button_gray);
      }
    }, [variant]);
  
    return (
      <Button
        className={className}
        variant={variant}
        disabled={disabled || loading}
        disableElevation
        onClick={onClick}
        color={color}
        size={size}
        fullWidth={fullWidth}
        endIcon={endIcon}
        startIcon={startIcon}
        type={type}
      >
        {loading && <CircularProgress size={15} color="inherit" />}
        {children}
      </Button>
    );
  }

  const CustomButtonGray = memo(CustomButtonMemoGray);

  export default CustomButtonGray
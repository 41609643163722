import styled from "@emotion/styled";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FaqSecWrap = styled(Box)`
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0%;
    left: 0px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(133, 239, 223, 0.24) 0%,
      rgba(71, 113, 255, 0) 100%
    );
    filter: blur(169.5px);
    transform: translate(-50%) rotate(2deg);
    width: 100vh;
    height: 100vh;
  }

  .faq_sec {
    .faq_content {
      margin-bottom: 50px;

      .MuiPaper-root {
        background: radial-gradient(
            81.73% 113.97% at 70.79% 50.77%,
            rgba(255, 255, 255, 0.14) 0%,
            rgba(255, 255, 255, 0.03) 100%
          )
          /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
        border: 1px solid rgba(255, 255, 255, 0.16);
        border-radius: 5px;
        margin-bottom: 20px !important;
        box-shadow: none;
        .MuiButtonBase-root {
          min-height: 65px;
          border-radius: 5px;
          background: -moz-linear-gradient(
            left,
            rgba(13, 17, 49, 0) 0%,
            rgba(10, 11, 39, 1) 47%,
            rgba(6, 4, 28, 1) 100%
          );
          background: -webkit-linear-gradient(
            left,
            rgba(13, 17, 49, 0) 0%,
            rgba(10, 11, 39, 1) 47%,
            rgba(6, 4, 28, 1) 100%
          );
          background: linear-gradient(
            to right,
            rgba(13, 17, 49, 0) 0%,
            rgba(10, 11, 39, 1) 47%,
            rgba(6, 4, 28, 1) 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000d1131', endColorstr='#06041c',GradientType=1 );

          @media (max-width: 479px) {
            min-height: 50px;
          }
        }
        .MuiAccordionSummary-content {
          margin: 0;
          .accordion_head {
            font-weight: 700;
            font-size: 24px;
            letter-spacing: 0.01em;
            color: var(--white);
            @media (max-width: 1199px) {
              font-size: 18px;
            }
            @media (max-width: 374px) {
              font-size: 15px;
            }
          }
        }
        .MuiAccordionSummary-expandIconWrapper {
          color: var(--white);
        }
      }
      .MuiAccordionDetails-root {
        border-radius: 5px;
        background: -moz-linear-gradient(
          left,
          rgba(13, 17, 49, 0) 0%,
          rgba(10, 11, 39, 1) 47%,
          rgba(6, 4, 28, 1) 100%
        );
        background: -webkit-linear-gradient(
          left,
          rgba(13, 17, 49, 0) 0%,
          rgba(10, 11, 39, 1) 47%,
          rgba(6, 4, 28, 1) 100%
        );
        background: linear-gradient(
          to right,
          rgba(13, 17, 49, 0) 0%,
          rgba(10, 11, 39, 1) 47%,
          rgba(6, 4, 28, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000d1131', endColorstr='#06041c',GradientType=1 );
      }
      .accordion_text {
        font-size: 20px;
        letter-spacing: 0.01em;
        color: var(--white);
        @media (max-width: 1199px) {
          font-size: 14px;
        }
      }
    }
  }
  .cmn_hdr {
    margin-bottom: 40px;
    &.text-center {
      text-align: center;
    }
  }
  .faq_btm {
    .MuiTypography-root {
      font-weight: 800;
      font-size: 36px;
      text-align: center;
      letter-spacing: 0.01em;
      color: var(--white);
      @media (max-width: 991px) {
        font-size: 24px;
      }
      @media (max-width: 575px) {
        font-size: 19px;
      }
      @media (max-width: 479px) {
        br {
          display: none;
        }
      }
      a {
        color: var(--activeColor);
      }
    }
  }
`;

export default function FaqSec() {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

    const FAQList = [
    {
      question: "What does Launch mean?",
      answer: "Launch stands for Initial Dex Offering and is a primary service offered by Pulsefinity. By participating in Launches, PLF token holders can acquire a project's tokens before they are listed, allowing investors to achieve higher returns on investment (ROI) from these projects." 
    },
    {
      question: "How do I participate in Pulsefinity Launches?",
      answer: `Participating in Pulsefinity Launches is a simple process that involves staking your PLF tokens to gain access to various tiers, each with its own set of features and pool weights. Pulsefinity offers a total of 6 tiers, all of which guarantee allocation to Launches.
      To participate in Pulsefinity Launches, follow these steps:
1. Acquire PLF tokens based on the desired tier and its corresponding USD value.
2. Stake your PLF tokens on the Pulsefinity platform.
3. Upon successful staking, you'll be allocated to a specific tier based on the value of your
staked PLF tokens.
4. As a tier member, you'll now have guaranteed allocation to Launches hosted on the Pulsefinity.` 
    },
    {
      question: "What are the utilities of PLF?",
      answer: "PLF is the token that empowers the entire Pulsefinity ecosystem. By staking PLF, users can participate in Launches and earn passive income in PLF, PLS, HEX, and PLSX. Additionally, developers contribute to the ecosystem by paying launchpad fees in PLF. The Pulsefinity team is constantly working to bring more relevant utilities to PLF."
    },
    {
      question: "How do I participate in the PLF sacrifice?",
      answer: `
      The PulseFinity Sacrifice is an event in which participants send a specific selection of cryptocurrencies in order to earn Finity Points, which will later be converted into PulseFinity (PLF) Tokens on PulseChain mainnet deployment. The conversion rate for the sacrifice is $1 for every 100- Finity points. The minimum sacrifice accepted is $50 USD in value at the time of sacrifice, and only ETH, HEX, USDC, USDT, BNB, and BUSD cryptocurrencies are eligible. Participants must not send funds from an exchange.
      By taking part in the Sacrifice Phase, participants can receive a volume bonus in Finity Points, which varies depending on the time of sacrifice. It is important to note that Finity Points and PLF tokens
      hold no value, you are sacrificing in support of new blockchain innovation on Pulsechain. Prior to the launch, the allocated PulseFinity Tokens will be airdropped to the wallet address used in the sacrifice, allowing participants to access their tokens when the platform goes live.`
    },
    {
      question: "How can I get back the tokens I purchased on an Launch?",
      answer: "After the Launch happens, the token purchased will be listed and only after the TGE (token generation event) PLF holders who purchased the tokens at the Launch will receive them. There are three ways holders get their tokens back: the first is via claim on Pulsefinitys claim platform on the profile section, the second is via airdrop sent by the Pulsefinity team and the third is via claim on the project’s platform."
    },
    {
      question: "What should I do after purchasing PLF? Can I already join the Launches?",
      answer: "To start enjoying all the benefits of being an PLF holder, you need to stake your PLF in one of the tiers available (to participate in the Launches). By having those steps done, you’ll be able to join the next Launch hosted on our platform and other benefits such as the staking, free Launch airdrops, giveaways and more."
    },
  ]

  return (
    <>
      <FaqSecWrap>
        <Box className="faq_sec cmn_gap">
          <Container fixed>
            <Box className="faq_wrap">
              <Box className="cmn_hdr text-center">
                <Typography variant="h2" className="cmn_head">
                  FAQ
                </Typography>
              </Box>
              <Box className="faq_content">
                {
                  FAQList.map((item, index) => (
                    <Accordion

                      expanded={expanded === `panel${index}`}
                      onChange={handleChange(`panel${index}`)}
                    >
                      <AccordionSummary

                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography variant="h3" className="accordion_head">
                          {item.question}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant="body1" className="accordion_text">
                          {item.answer}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))
                }
              </Box>
              <Box className="faq_btm">
                <Typography variant="h3">
                  Haven't found the answers you are looking for? <br /> Contact
                  us at
                  <a href="mailto:contact@pulsefinity.finance">
                    {" "}
                    contact@pulsefinity.finance
                  </a>
                </Typography>
              </Box>
            </Box>
          </Container>
        </Box>
      </FaqSecWrap>
    </>
  );
}

import AllAssets from "@/components/AllAssets";
import AllModals from "@/components/AllModals";
import DataWrapper from "@/components/DataWrapper/DataWrapper";
import assest from "@/json/assest";
import Wrapper from "@/layout/wrapper/Wrapper";
import CustomButton from "@/ui/Buttons/CustomButton";
import CustomButtonGray from "@/ui/Buttons/CustomButtonGray";
import AboutSkeleton from "@/ui/Skeletons/AboutSkeleton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/system";
import * as React from "react";
import useNotiStack from "hooks/useNotistack";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { List, ListItem, Paper } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Seo from "@/components/Seo/Seo";
import CommonSlider from "@/components/CommonSlider/CommonSlider";
import CommonJoinSec from "@/components/CommonJoinSec/CommonJoinSec";
import ApplyBox from "@/components/ApplyBox/ApplyBox";
import PartnerSection from "@/components/PartnerSection/PartnerSection";
import ConnectSection from "@/components/ConnectSection/ConnectSection";
import LaunchSection from "@/components/LaunchSection/LaunchSection";
import FaqSecWrap from "@/components/FaqSec/FaqSec";
import { useSelector } from "react-redux";
import PLF from "./../components/PLF Sacrifice/plf";

const AllButtons = dynamic(() => import("@/components/AllButtons"), {
  ssr: true,
});
const AllInputs = dynamic(() => import("@/components/AllInputs"), {
  ssr: true,
});
const AllAvatar = dynamic(() => import("@/components/AllAvatar"), {
  ssr: true,
});
const AllColors = dynamic(() => import("@/components/AllColors"), {
  ssr: true,
});
const HomeSection = dynamic(() => import("@/components/HomeSection"), {
  ssr: true,
});

// === get serverside methods ===4
export const getServerSideProps: GetServerSideProps = async (context) => {
  return {
    props: {},
  };
};

const ApplySec = styled(Box)`
  .apply_sec {
    padding: 200px 0;
    @media (max-width: 1199px) {
      padding: 100px 0;
    }
    @media (max-width: 479px) {
      padding: 50px 0;
    }
  }
  .apply_wrap_upper_content {
    max-width: 800px;
    position: relative;
    z-index: 5;
    @media (max-width: 1199px) {
      max-width: 500px;
    }
    @media (max-width: 991px) {
      max-width: 375px;
    }
    @media (max-width: 575px) {
      max-width: 245px;
    }
    p {
      font-weight: 800;
      font-size: 20px;
      letter-spacing: 0.01em;
      color: var(--white);
      max-width: 554px;
      @media (max-width: 991px) {
        font-size: 14px;
      }
    }
    h2.MuiTypography-root {
      margin-bottom: 20px;
      text-transform: none;
      @media (max-width: 575px) {
        font-size: 26px;
      }
    }
  }
  .apply_wrap_upper {
    padding: 30px 50px;
    position: relative;
    z-index: 5;
    border: 1px solid rgba(255, 56, 235, 0.19);
    border-radius: 5px;
    max-width: 1067px;
    @media (max-width: 991px) {
      padding: 30px 20px;
    }
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      background: url("/assets/images/landingRocketBack.svg") no-repeat center;
      background-size: cover;
      transform: rotate(-180deg);
      position: absolute;
      left: 0;
      top: 0;
      pointer-events: none;
      z-index: -1;
    }
    .rocket_img {
      position: absolute;
      right: -17%;
      top: 55%;
      transform: translateY(-50%);
      height: 900px;
      @media (max-width: 1199px) {
        height: 700px;
        right: -12%;
      }
      @media (max-width: 991px) {
        height: 500px;
        /* right: -12%; */
      }
      @media (max-width: 479px) {
        position: static;
        transform: none;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .apply_wrap_btm {
    margin-top: 30px;
    position: relative;
    z-index: 1;
    .MuiButtonBase-root {
      min-width: 850px;
      @media (max-width: 1199px) {
        min-width: 650px;
      }
      @media (max-width: 991px) {
        min-width: 350px;
      }
      @media (max-width: 479px) {
        min-width: auto;
        width: 100%;
      }
    }
  }
  .apply_logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 236px;
    margin-bottom: 25px;
    @media (max-width: 991px) {
      width: 165px;
      margin-bottom: 15px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

const BannerSec = styled(Box)`
  margin-top: 120px;
  /* @media (max-width: 1199px) {
    margin-top: 65px;
  }
  @media (max-width: 899px) {
    margin-top: 90px;
  } */
  .banner-grid {
    @media (max-width: 899px) {
      flex-direction: column-reverse;
    }
  }
  .banner_left {
    position: relative;
    z-index: 11;
    h1 {
      font-style: normal;
      font-weight: 800;
      font-size: 64px;
      line-height: 1.2;
      letter-spacing: 0.01em;
      color: var(--white);
      @media (max-width: 1600px) {
        font-size: 60px;
      }
      @media (max-width: 1199px) {
        font-size: 40px;
        max-width: 465px;
      }
      @media (max-width: 899px) {
        max-width: none;
        font-size: 28px;
        text-align: center;
      }
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
      letter-spacing: 0.01em;
      color: var(--white);
      margin: 40px 0 67px 0;
      width: 100%;
      max-width: 658px;
      span {
        font-weight: 700;
      }
      @media (max-width: 899px) {
        font-size: 14px;
        margin: 20px 0 30px 0;
        text-align: center;
      }
    }
    .btn_area {
      @media (max-width: 899px) {
        text-align: center;
      }

      @media (max-width: 479px) {
        display: flex;
        /* flex-wrap: wrap; */
      }
      button {
        width: 100%;
        max-width: 303px;
        @media (max-width: 1199px) {
          max-width: 220px;
        }
        @media (max-width: 767px) {
          max-width: 195px;
        }
        @media (max-width: 479px) {
          max-width: none;
          width: 100%;
        }
        &:last-child {
          margin: 0 0 0 34px;
          @media (max-width: 1199px) {
            margin: 0 0 0 20px;
          }
          @media (max-width: 599px) {
            margin: 0 0 0 8px;
          }
        }
      }
    }
    .support_sec {
      margin-top: 56px;
      h6 {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.01em;
        color: var(--white);
        margin-bottom: 24px;
      }
      .MuiList-root {
        display: flex;
        width: 100%;
        max-width: 670px;
        .MuiListItem-root {
          flex-basis: 16.66%;
          max-width: 16.66%;
          cursor: pointer;
          @media (max-width: 899px) {
            flex-basis: 11.66%;
            max-width: 11.66%;
          }
          @media (max-width: 599px) {
            flex-basis: 50px;
            max-width: 50px;
            padding: 8px;
          }

          img {
            &:hover {
              filter: invert(43%) sepia(95%) saturate(1089%) hue-rotate(160deg)
                brightness(161%) contrast(101%);
            }
          }
        }
      }
    }
  }
  .right_ban {
    position: relative;
    .video_wrapper {
      position: relative;
      padding-bottom: 80.25%;
      overflow: hidden;
      width: 1200px;
      margin-left: -50%;
      margin-right: 0;
      @media (max-width: 1599px) {
        width: 1000px;
      }
      @media (max-width: 1199px) {
        width: 700px;
      }
      @media (max-width: 899px) {
        margin: 0 auto;
        width: auto;
        padding: 0;
      }
      video {
        mix-blend-mode: lighten;
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 20%;
        left: 0;
        @media (max-width: 899px) {
          position: static;
        }
      }
    }
  }
`;

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "var(--white)",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));

const Upcoming = styled(Box)`
  position: relative;
  z-index: 11;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(133, 239, 223, 0.24) 0%,
      rgba(71, 113, 255, 0) 100%
    );
    -webkit-transform: rotate(-99.39deg);
    -moz-transform: rotate(-99.39deg);
    -ms-transform: rotate(-99.39deg);
    transform: translate(-50%, -50%) rotate(177deg);
    width: 100vw;
    height: 100vh;
  }
  .see_btn {
    margin-top: 47px;
    button {
      width: 100%;
      max-width: 233px;
      @media (max-width: 899px) {
        margin: auto;
      }
      span {
        justify-content: center;
      }
    }
    &.see_btn_mble {
      @media (max-width: 899px) {
        text-align: center;
        margin-top: 100px;
      }
    }
  }

  h2 {
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 1.2;
    letter-spacing: 0.01em;
    color: var(--white);
    @media (max-width: 1199px) {
      font-size: 40px;
    }
    @media (max-width: 991px) {
      font-size: 30px;
    }
  }
  .right_txt {
    text-align: right;
    h5 {
      font-weight: 800;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: var(--white);
      margin-bottom: 7px;
      span {
        color: var(--activeColor);
      }
      @media (max-width: 899px) {
        font-size: 14px;
        margin-top: 15px;
        text-align: center;
      }
    }
    p {
      @media (max-width: 899px) {
        font-size: 10px;

        text-align: center;
      }
    }
  }
  .upcoming_ido {
    &.margin_cls_up {
      margin-top: 108px;
      @media (max-width: 899px) {
        margin-bottom: 150px;
      }
    }
    .head_sec {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 899px) {
        display: block;
        text-align: center;
      }
    }
  }
`;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const SupportSec = styled(Box)`
  padding-bottom: 100px;
  .support_wrap {
    .cmn_hdr {
      margin-bottom: 70px;
      &.text_center {
        text-align: center;
      }
      h3 {
        font-weight: 800;
        font-size: 24px;
        letter-spacing: 0.01em;
        color: var(--white);
      }
    }
  }
  .support_otr {
    text-align: center;
    position: relative;
    figure {
      width: 70px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      &.fade {
        img {
          opacity: 0.3;
        }
      }
    }
    .support_title {
      font-weight: 700;
      font-size: 12px;
      text-align: center;
      letter-spacing: 0.01em;
      color: var(--white);
      max-width: 57px;
      margin: auto;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .support_content {
    max-width: 670px;
    margin: auto;
  }
`;

export default function Home() {
  const [progress, setProgress] = useState(0);
  const { toastSuccess, toastError } = useNotiStack();

  // @ts-ignore
  const upcoming = useSelector((state) => state.launchpad.upcoming);
  // @ts-ignore
  const active = useSelector((state) => state.launchpad.active);

  useEffect(() => {
    // toastError("err");
    // toastSuccess("This is success");
  }, []);

  const cardLoop = [1, 2, 3, 4, 5, 6, 7];
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const supportItems = [
    {
      support_img_path: `${assest.sup1}`,
      support_text: "",
    },
    {
      support_img_path: `${assest.sup2}`,
      support_text: "Comming Soon",
    },
    {
      support_img_path: `${assest.sup3}`,
      support_text: "Comming Soon",
    },
    {
      support_img_path: `${assest.sup4}`,
      support_text: "Comming Soon",
    },
    {
      support_img_path: `${assest.sup5}`,
      support_text: "Comming Soon",
    },
    {
      support_img_path: `${assest.sup6}`,
      support_text: "Comming Soon",
    },
  ];

  return (
    <Wrapper>
      <Seo title="Home" canonical={""} description={""} url={""} image={""} />
      <BannerSec>
        <Container fixed>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} className="banner-grid">
              <Grid item md={7} xs={12}>
                <Box className="banner_left">
                  <Typography variant="h1">
                    Become an early investor in top DeFi projects on Pulsechain
                  </Typography>
                  <Typography variant="body1">
                    Pulsefinity is the leading DeFi incubator and launchpad on
                    Pulsechain. By{" "}
                    <span>
                      staking $PLF, you become eligible to guaranteed
                      allocations
                    </span>{" "}
                    to innovative projects before everyone else.
                  </Typography>
                  <Box className="btn_area">
                    <CustomButton
                      type="button"
                      onClick={() => window.open("/migrate-token", "_self")}
                    >
                      <Typography variant="button">Migrate Token</Typography>
                    </CustomButton>
                    <CustomButtonGray
                      type="button"
                      onClick={() => window.open("/price-discovery", "_self")}
                    >
                      <Typography variant="button">Price Discovery</Typography>
                    </CustomButtonGray>
                  </Box>
                  <Box className="support_sec">
                    <Typography variant="h6">Supported Blockchains</Typography>
                    <List>
                      <ListItem>
                        <Image
                          src={assest.block1}
                          alt="img"
                          width={56}
                          height={56}
                        />
                      </ListItem>
                      {/*<ListItem>
                        <Image
                          src={assest.anglelogo}
                          alt="img"
                          width={56}
                          height={56}
                        />
                      </ListItem>
                      <ListItem>
                        <Image
                          src={assest.networkicon1}
                          alt="img"
                          width={56}
                          height={56}
                        />
                      </ListItem>
                      <ListItem>
                        <Image
                          src={assest.networkicon3}
                          alt="img"
                          width={56}
                          height={56}
                        />
                      </ListItem>
                      {/*<ListItem>
                        <Image
                          src={assest.networkicon2}
                          alt="img"
                          width={56}
                          height={56}
                        />
                      </ListItem>
                      <ListItem>
                        <Image
                          src={assest.networkicon4}
                          alt="img"
                          width={56}
                          height={56}
                        />
                      </ListItem>*/}
                    </List>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={5} xs={12} className="right_ban">
                {/* <Image
                  src={assest.plan_shadow}
                  alt="shadow image"
                  width={1000}
                  height={1000}
                  className="shadow_img"
                />
                <figure>
                  <Image
                    priority
                    src={assest.plane}
                    alt="img"
                    width={684}
                    height={438}
                    layout="responsive"
                    sizes="(max-width: 768px) 100vw,
                    (max-width: 1200px) 50vw,
                    33vw"
                  />
                </figure> */}
                <Box className="video_wrapper">
                  <video
                    width={500}
                    height={540}
                    loop={true}
                    autoPlay
                    muted
                    playsInline={true}
                  >
                    <source src={assest.plainVideo} type="video/mp4" />
                  </video>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </BannerSec>

      {/*<Upcoming>
        <Container fixed>
          <Box className="cmn_gap">
            {
              upcoming?.length > 0 && (
                <Box className="upcoming_ido">
                  <Box className="head_sec">
                    <Typography variant="h2">Upcoming Launches</Typography>
                    <Box className="right_txt">
                      <Typography variant="h5">
                        There are <span>{upcoming?.length}</span> upcoming Launches
                      </Typography>
                      <Typography variant="body2">
                        Click on an item to view more
                      </Typography>
                    </Box>
                  </Box>
                  <CommonSlider 
                    count={1} 
                    pagetype="upcoming" 
                    upcoming={false}
                    launchpads={upcoming}
                  />
                  <Box className="see_btn" sx={{ display: { xs: "none", sm: "block" } }}>
                    <CustomButton 
                      type="button" 
                      sx={{display:{md:'block',xs:'none'}}}
                      onClick={() => window.open("/launchpads", "_self")}
                    >
                      <Typography variant="button">View All</Typography>
                    </CustomButton>
                  </Box>
                </Box>
              )
            }
       
            {
              active?.length > 0 && (
                <Box className="upcoming_ido margin_cls_up">
                  <Box className="head_sec">
                    <Typography variant="h2">Active Launches</Typography>
                    <Box className="right_txt">
                      <Typography variant="h5">
                        There are <span>{active?.length}</span> active Launches
                      </Typography>
                      <Typography variant="body2">
                        Click on an item to view more 
                      </Typography>
                    </Box>
                  </Box>
                  <CommonSlider 
                    count={1} 
                    pagetype="active" 
                    upcoming={false} 
                    launchpads={active}
                  />
                  <Box className="see_btn see_btn_mble" >
                    <CustomButton 
                      type="button" 
                      sx={{display:{md:'block',xs:'none'}}}
                      onClick={() => window.open("/launchpads", "_self")}
                    >
                      <Typography variant="button">View All</Typography>
                    </CustomButton>
                
                  </Box>
                  <Box className="see_btn see_btn_mble">
                    <CustomButton 
                      type="button" 
                      sx={{display:{md:'none',xs:'block'}}}
                      onClick={() => window.open("/launchpads", "_self")}
                    >
                      <Typography variant="button">View Launches</Typography>
                    </CustomButton>
                    </Box>
                </Box>
              )
            }
         
          </Box>
        </Container>
          </Upcoming>*/}

      <LaunchSection />

      <ConnectSection />

      <CommonJoinSec />

      <PartnerSection />

      {/* <SupportSec>
        <Box className="support_sec">
          <Container fixed>
            <Box className="support_wrap">
              <Box className="cmn_hdr text_center">
                <Typography variant="h3">Supported Blockchains</Typography>
              </Box>
              <Box className="support_content">
                <Grid container spacing={2}>
                  {supportItems.map((data, index) => {
                    return (
                      <Grid item md={2} xs={6} key={index}>
                        <Box className="support_otr">
                          {data.support_text !== "" ? (
                            <figure className="fade">
                              <Image
                                src={data.support_img_path}
                                alt="img"
                                width="50"
                                height="50"
                              ></Image>
                            </figure>
                          ) : (
                            <figure>
                              <Image
                                src={data.support_img_path}
                                alt="img"
                                width="50"
                                height="50"
                              ></Image>
                            </figure>
                          )}

                          <Typography variant="h6" className="support_title">
                            {data.support_text}
                          </Typography>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Box>
          </Container>
        </Box>
      </SupportSec> */}

      <FaqSecWrap />

      <ApplyBox />
    </Wrapper>
  );
}

import assest from "@/json/assest";
import CustomButton from "@/ui/Buttons/CustomButton";
import CustomButtonGray from "@/ui/Buttons/CustomButtonGray";
import CustomButtonPurple from "@/ui/Buttons/CustomButtonPurple";
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import Image from "next/image";
import React from "react";

const ConnectSec = styled(Box)`
  .connect_bottom {
    @media (max-width: 767px) {
      max-width: 530px;
      margin: auto;
    }
    @media (max-width: 575px) {
      max-width: 230px;
      margin: auto;
    }
  }
  .connect_content {
    @media (max-width: 991px) {
      margin-bottom: 50px;
    }

    h2 {
      text-transform: none;
      span {
        color: var(--activeColor);
      }
    }

    .connect_row {
      .connect_col {
        .connect_btm_content {
          margin-bottom: 90px;
          @media (max-width: 599px) {
            margin-bottom: 60px;
          }
        }
      }
    }

    .btn_list {
      display: flex;
      align-items: center;
      @media (max-width: 899px) {
        justify-content: center;
      }
      li {
        padding: 0;
        width: auto;
        display: inline-block;
        &:not(:last-child) {
          margin-right: 20px;
        }
        button {
          padding: 10px 17px;
          min-width: 233px;
        }
      }
    }
  }
  .connect_top {
    .MuiGrid-root {
      align-items: start;
    }
  }
  .connect_img {
    position: relative;

    figure {
      height: 450px;
      @media (max-width: 479px) {
        height: auto;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .connect_btm_content {
    @media (max-width: 1199px) {
      padding: 40px 15px 15px 40px;
      margin-bottom: 50px;
    }
    @media (max-width: 599px) {
      padding: 0;
    }
    h3 {
      font-weight: 700;
      font-size: 20px;
      letter-spacing: 0.01em;
      color: var(--white);
      max-width: 250px;
      margin-bottom: 20px;
      @media (max-width: 1199px) {
        font-size: 24px;
        max-width: 320px;
      }
      @media (max-width: 599px) {
        font-size: 20px;
        max-width: 100%;
        text-align: center;
        color: var(--activeColor);
        margin-bottom: 10px;
      }
    }
    p.MuiTypography-root {
      max-width: 250px;
      letter-spacing: 0.01em;
      color: var(--white);
      @media (max-width: 1199px) {
        font-size: 14px;
        max-width: 250px;
      }
      @media (max-width: 599px) {
        max-width: 100%;
        text-align: center;
      }
    }
  }
  .user_box {
    width: 91px;
    height: 91px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    left: -25px;
    top: -25px;
    background: url("/assets/images/UserButton.svg") no-repeat center;
    background-size: 100px;
    border: 1px solid rgba(255, 56, 235, 0.16);
    border-radius: 5px;
    z-index: 5;
    @media (max-width: 1199px) {
      width: 60px;
      height: 60px;
      img {
        max-width: 25px;
      }
    }
  }
  .connect_top {
    margin-bottom: 140px;
    @media (max-width: 1199px) {
      margin-bottom: 50px;
    }
    @media (max-width: 899px) {
      margin-bottom: 0px;
    }
  }

  .connect_title {
    margin-bottom: 100px;
    @media (max-width: 899px) {
      margin-bottom: 40px;
    }
    @media (max-width: 599px) {
      margin-bottom: 0px;
    }
    h2 {
      font-family: "Manrope";
      font-weight: 800;
      font-size: 48px;
      letter-spacing: 0.01em;
      color: var(--white);
      max-width: 790px;
      @media (max-width: 899px) {
        text-align: center;
        font-size: 28px;
      }
    }
  }
  .see_btn.launch {
    text-align: center;

    span{
      font-size: 16px;

    }
    button{
      min-width: 165px;
    }
  }
`;
const ConnectSection = () => {
  const accessList = [
    {
      access_head: "Exclusive Access to Top-Tier Projects on Pulsechain",
      access_txt:
        "As a launchpad and incubator, we diligently seek out the most promising and innovative projects for our holders to invest in, providing unparalleled opportunities.",
    },
    {
      access_head: "Rigorous Project Due Diligence",
      access_txt:
        "Our team takes on the challenge of thoroughly vetting projects, personally engaging with their teams, and meticulously examining whitepapers before greenlighting any sale.",
    },
    {
      access_head: "Early Access to Tokens",
      access_txt:
        "Gain a competitive edge by securing pre-launch access to tokens at listing prices before everyone else.",
    },
    {
      access_head: "Effortless Passive Income through Staking",
      access_txt:
        "Stake your PLF tokens to earn rewards in HEX, PLS, and PLSX while simultaneously gaining access to exclusive Launch sales. By staking, you secure a tier that unlocks an array of premium features.",
    },
  ];
  return (
    <ConnectSec>
      <Box className="connect_sec cmn_gap">
        <Container fixed>
          <Box className="connect_wrap">
            <Box className="connect_title">
              <Typography variant="h2" className="cmn_head">
              Connecting unique projects to pulsechain users
              </Typography>
            </Box>
            <Box
              className="connect_img"
              sx={{ display: { xs: "block", sm: "none" } }}
            >
              <figure>
                <Image
                  src={assest.boxBlue}
                  alt="img"
                  width={1000}
                  height={800}
                ></Image>
              </figure>
            </Box>
            <Box className="connect_top">
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Box className="connect_content">
                    <Grid container spacing={2} className="connect_row">
                      {accessList.map((data, index) => {
                        return (
                          <Grid
                            item
                            md={6}
                            sm={6}
                            xs={12}
                            key={index}
                            className="connect_col"
                          >
                            <Box className="connect_btm_content">
                              <Typography variant="h3" className="cmn_head">
                                {data.access_head}
                              </Typography>
                              <Typography variant="body1">
                                {data.access_txt}
                              </Typography>
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                    <List className="btn_list">
                      <ListItem
                        sx={{
                          display: {
                            xs: "none !important",
                            sm: "flex !important",
                          },
                        }}
                      >
                        <CustomButton type="button"
                          onClick={() => window.open("/price-discovery", "_self")}
                        >
                          <Typography variant="button">
                            Price Discovery 
                          </Typography>
                      </CustomButton>
                      </ListItem>
                      <ListItem
                        sx={{
                          display: {
                            xs: "none !important",
                            sm: "flex !important",
                          },
                        }}
                      >
                        <CustomButtonGray type="button">
                        <Typography 
                          variant="button" 
                          onClick={() => window.open("https://pulsefinity.gitbook.io/pulsefinity/participating-in-pulsefinity-idos/how-to-enter-a-ido", "_blank")}
                        >
                          How To Start
                        </Typography>
                        </CustomButtonGray>
                      </ListItem>
                    </List>
                    <Box
                      className="see_btn launch"
                      sx={{
                        display: {
                          xs: "block !important",
                          sm: "none !important",
                        },
                      }}
                    >
                      <CustomButton 
                        type="button"
                        onClick={() => window.open("/create-launchpad", "_self")}
                      >
                        <Typography variant="button">Launch Project</Typography>
                      </CustomButton>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box
                    className="connect_img"
                    sx={{ display: { xs: "none", sm: "block" } }}
                  >
                    <figure>
                      <Image
                        src={assest.boxBlue}
                        alt="img"
                        width={1000}
                        height={800}
                      ></Image>
                    </figure>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
    </ConnectSec>
  );
};

export default ConnectSection;
